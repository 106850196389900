@use 'src/styles/6-abstracts/variables' as var;

.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  background-color: var.$background-color-fifth;
  padding: 15px;
  border-radius: 4px;
  width: fit-content;
}

.actionButton {
  background-color: var.$background-color-blue;
  color: white;
  
  &:hover {
    background-color: darken(var.$background-color-blue, 10%);
  }
}
