@use 'src/styles/6-abstracts/variables' as var;

// Define CSS variables for mui DataGrid design
:export {
  textColor: var.$font-color-primary;
  headerColor: var.$font-color-third;
  headerFontFamily: var.$font-family-primary;
  headerFontSize: var.$font-size-s;
  rowFontFamily: var.$font-family-primary-bold;
  tabBgColor: var.$background-color-primary;
  tabBorder: 1px solid var.$border-color-primary;
  tabBorderRadius: var.$border-radius-large;
}

.tableContainer {
  background-color: var.$background-color-primary !important;
  border: 1px solid var.$border-color-primary !important;
  border-radius: var.$border-radius-large !important;
  
  :global {
    .MuiTable-root {
      background-color: transparent;
    }

    .MuiTableHead-root {
      th {
        color: var.$font-color-third !important;
        font-family: var.$font-family-primary !important;
        font-size: var.$font-size-s !important;
        background-color: transparent !important;
        border-bottom: unset !important;
        padding: 16px !important;
      }
    }

    .MuiTableBody-root {
      td {
        color: var.$font-color-primary !important;
        font-family: var.$font-family-primary-bold !important;
        border-bottom: 1px solid rgba(var.$font-color-primary, 0.1) !important;
        padding: 16px !important;
        background-color: transparent !important;
      }

      tr:last-child td {
        border-bottom: none !important;
      }
    }
  }
}
